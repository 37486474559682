<template>
  <q-form ref="editForm">
    <c-card title="기본정보" :noMarginPadding="true" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-label-text title="교유계획명" :value="educationInfo.educationName"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-label-text title="교육과정" :value="educationInfo.educationCourseName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-label-text title="교육종류1" :value="educationInfo.educationKindCdLargeName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-label-text title="교육종류2" :value="educationInfo.educationKindCdSmallName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-label-text title="사업장" :value="educationInfo.plantName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-label-text title="주관부서" :value="educationInfo.departmentDeptName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-label-text title="교육방법" :value="educationInfo.educationMethodName ? educationInfo.educationMethodName : '-'"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-label-text title="문제풀이" :value="educationInfo.eduQuestionFlag"></c-label-text>
        </div>
      </template>
    </c-card>
    <c-card title="동영상 시청" :noMarginPadding="true" class="cardClassDetailForm mobilevod mobilevod-hide" v-show="educationInfo.educationMethodCd == 'EMC999999'">
      <template slot="card-detail">
        <div class="player-center text-center col-12">
          <video-player ref="videoPlayer"
            :options="playerOptions"
            @playing="onPlayerStart"
            @play="onPlay"
            @pause="onPause"
            @ended="onPlayerEnded($event)">
          </video-player>
        </div>
        <div class="vod-label-layer col-12">
          <div class="vod-label-div text-center" v-if="!disabled">재생 시간 : {{ vodPlayDuration }} / {{ vodTotalDuration }}</div>
          <div class="vod-label-div text-center">
            <c-btn label="처음부터 재생" class="full-width" icon="skip_next" color="orange" @btnClicked="onPlayerPlayFirst" v-if="isFirstPlay && !isPlay && !isEduEnd && !disabled && educationInfo.checkCompleteFlag!='Y'" /> 
          </div>
          <div class="vod-label-div text-center">
            <c-btn :label="playLabel" class="full-width" :icon="playIcon" :color="playColor" @btnClicked="onPlayerPlay" v-if="!isEduEnd && !disabled && educationInfo.checkCompleteFlag!='Y'" />
          </div>
        </div>
      </template>
    </c-card>
    <c-card title="서명 및 의견" class="cardClassDetailInfo" v-show="(isEnd && !isPlay || educationInfo.educationMethodCd != 'EMC999999') && !disabled">
      <!-- <template slot="card-button">
        <q-btn-group outline >
        </q-btn-group> 
      </template> -->
      <template slot="card-detail">
        <div class="vod-label-div text-center" v-if="questionWithoutVod || questionWithVod">서명/의견은 문제풀이 후 입력할 수 있습니다.</div>
        <div class="vod-label-div text-center" v-if="educationInfo.isQuestionFlag === 'Y'">문제풀이 획득점수/통과기준점수 :  {{ educationInfo.score }} / {{ educationInfo.eduQuestionPassScore }}</div>
        <div class="col-xs-12 col-sm-12" v-show="educationInfo.isQuestionFlag === 'Y' || educationInfo.eduQuestionFlag === 'N'">
          <div class="container">
            <div class="items-center all-pointer-events">
              <b><font id="signatureTitle">&nbsp;&nbsp;서명</font></b>
            </div>
            <VueSignaturePad id="signature" height="200px" ref="signaturePad" :options="options"/>
          </div>
          <div style="margin-top:40px !important;">
            <c-textarea
              :disabled="disabled"
              label="의견"
              name="opinionContent"
              v-model="educationInfo.opinionContent">
            </c-textarea>
          </div>
        </div>
      </template>
    </c-card>
    <div class="p-3">
      <div class="vod-label-div container text-center" style="width:100% !important" v-if="educationInfo.checkCompleteFlag==='Y'">
        이수완료 : {{ educationInfo.evaluationDate }}</div>
      <div class="vod-label-div container text-center" style="width:100% !important" v-if="disabled">
        교육완료 또는 이수완료 되었습니다.</div>
      <div class="vod-label-div-text" v-if="!disabled && educationInfo.educationMethodCd == 'EMC999999'">
        ※ 재생/정지/완료 버튼을 클릭하지 않고 창을 닫을 경우 동영상 재생이력이 저장되지 않습니다.
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn flat label="문제풀이" icon="task_alt" color="teal-6" @btnClicked="openQuiz" 
          v-show="educationInfo.checkCompleteFlag != 'Y' && educationInfo.isQuestionFlag != 'Y' && educationInfo.eduQuestionFlag == 'Y' && 
          (educationInfo.educationMethodCd == 'EMC999999' && isEnd && !isPlay || educationInfo.educationMethodCd != 'EMC999999')" />
          <span v-if="isEnd && !isPlay && !isEduEnd && !disabled && (educationInfo.isQuestionFlag === 'Y' || educationInfo.eduQuestionFlag === 'N')" >
            <c-btn flat label="서명지우기" icon="remove" color="red" @btnClicked="eraseSignature"/>
            <c-btn flat label="이수완료" icon="task_alt" color="blue" @btnClicked="saveAsSvg"/>
          </span>
          <span v-if="educationInfo.educationMethodCd != 'EMC999999' && educationInfo.eduQuestionFlag === 'Y' && educationInfo.isQuestionFlag === 'Y' && educationInfo.checkCompleteFlag !== 'Y'">
            <c-btn flat label="서명지우기" color="red" :showLoading="false" @btnClicked="eraseSignature" />
            <c-btn flat label="이수완료" color="blue" :showLoading="false" @btnClicked="saveAsSvg" />
          </span>
          </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import mobileConfig from '@/js/mobileConfig';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
Vue.use(VueSignaturePad)
export default {
  name: 'education-complete',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
  },
  data() {
    return {
      isEduEnd: false,
      isEduEndDate: '',
      electronSignature: null,
      options: {
        penColor: "#000000",
      },
      isEduQuestionPass: true,
      isEnd: false,
      isFirstPlay: true,
      isPost: true,
      isInterval: null,
      playDuraionTime: 0,
      isPlay: false,
      playLabel: '재생',
      playColor: 'blue',
      playIcon: 'play_arrow',
      vodTotalDuration: '',
      vodTotalDurationS: 0,
      vodPlayDuration: '',
      playerOptions: {
        autoplay: false,
        controls: true,
        playsinline: true,
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          playToggle: false,
          remainingTimeDisplay: true,
          progressControl: true,
          qualitySelector: true,
        },
        width: (window.innerWidth - 40) + 'px',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        opinionContent: '',
        electronSignature: '',
        attendeesId: '',
        attendeesNo: '',
        attendanceTime: '',
        checkCompleteFlag: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],

        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        isQuestionFlag: 'N',
        score: 0
      },
      editable: true,
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
      updateUrl: '',
      insertUrl: '',
      deleteUrl: '',
      searchUrl: '',
      items: [],
      getUrl: '',
      updateSignatureUrl: '',
      rowIndex: 0,
      windowWidth: '',
      vodInsertUrl: '',
      vodUpdateUrl: '',
    };
  },
  computed: {
    disabled() {
      return (Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd === 'ESC000010' || this.educationInfo.checkCompleteFlag === 'Y')
    },
    player () {
      return this.$refs.videoPlayer.player
    },
    questionWithoutVod() {
      return this.educationInfo.educationMethodCd!='EMC999999' && this.educationInfo.eduQuestionFlag=='Y' && this.educationInfo.checkCompleteFlag!='Y' && this.educationInfo.isQuestionFlag != 'Y'
    },
    questionWithVod() {
      return this.educationInfo.educationMethodCd == 'EMC999999' && this.isEnd && !this.isPlay && this.educationInfo.eduQuestionFlag=='Y' && this.educationInfo.checkCompleteFlag != 'Y' && this.educationInfo.isQuestionFlag != 'Y'
    }

  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    // 해당 교육용 동영상정보 호출
    this.$http.url = this.$format(selectConfig.sop.edu.result.get.url, this.popupParam.eduEducationId);
    this.$http.type = 'GET';
    this.$http.request((_result) => {
      if (_result.data.educationMethodCd == 'EMC999999') {
        if (process.env.VUE_APP_UPLOAD_TYPE == 'AWS') {
          // AWS S3용
          this.$http.url = selectConfig.com.upload.vodDownS3.url;
          this.$http.type = 'GET';
          this.$http.isLoading = false;
          this.$http.param = {
            sysAttachFileId: _result.data.vod
          }
          this.$http.request((_result2) => {
            const src = _result2.data
            this.playVideo(src)
            this.init();
          },);
        } else {
          // DISK 저장용
          const src = process.env.VUE_APP_API_URL + selectConfig.com.upload.vodDown.url + '/' + _result.data.vod;
          this.playVideo(src)
          this.init();
        }
      } else {
        this.init();
      }
    },);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.edu.result.get.mobile.url;
      this.updateSignatureUrl = mobileConfig.sop.edu.plan.update.targetUser.signature.url;
      this.vodInsertUrl = transactionConfig.sop.edu.vod.insert.url
      this.vodUpdateUrl = transactionConfig.sop.edu.vod.update.url
      if (!this.$refs.signaturePad) {return;}
      // signaturePad.lockSignaturePad();
      this.getDetail();
    },
    onSighRemove() {
      this.$refs.signaturePad.clearSignature();
    },
    getDetail() {
      if (this.popupParam.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduEducationId, this.$store.getters.user.userId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data
          console.log(_result.data)
          if (this.educationInfo.eduQuestionFlag == 'Y') {
            this.isEduQuestionPass = false;
          } else {
            this.isEduQuestionPass = true;
          }
          if (this.educationInfo.electronSignature) {
            this.$refs.signaturePad.fromDataURL(this.educationInfo.electronSignature);
          }
          if (this.educationInfo.documentStatusCd === 'ESC000010' || this.educationInfo.checkCompleteFlag === 'Y') {
            this.$refs.signaturePad.lockSignaturePad();
            this.isEduQuestionPass = true
          }
          if (this.educationInfo.educationMethodCd == 'EMC999999') {
            var _div = document.getElementsByClassName('mobilevod')[0];
            if (_div) {
              _div.classList.remove('mobilevod-hide');
            }
            this.getData();
          }
        })
      }
    },
    convertFloat(number) {
      if (number) {return parseFloat(number)
      } else {return 0;}
    },
    getData() {
      if (this.popupParam.eduEducationId) {
        this.$http.url = selectConfig.sop.edu.vod.get.url;
        this.$http.type = 'GET';
        this.$http.isLoading = false;
        this.$http.param = {
          eduEducationId: this.popupParam.eduEducationId,
          attendeesId: this.$store.getters.user.userId,
        }
        this.$http.request((_result) => {
          if (_result.data) {
            this.playDuraionTime = this.convertFloat(this.playDuraionTime) + this.convertFloat(_result.data.vodPlayTime);
            this.vodPlayDuration = this.getPlayTime(this.playDuraionTime);
            this.vodTotalDurationS = this.convertFloat(_result.data.vodTotalTime);
            this.vodTotalDuration = this.getPlayTime(this.vodTotalDurationS);
            if (_result.data.vodCurrentTime) {
              this.player.currentTime(this.convertFloat(_result.data.vodCurrentTime))
            }
            this.playLabel = '연속재생';
            this.isPost = false;
            this.isFirstPlay = true;

            if ((this.convertFloat(this.playDuraionTime) / this.convertFloat(this.vodTotalDurationS)) * 100 > 90) {
              this.isEnd = true;
            }
            
            if (_result.data.checkCompleteFlag == 'Y') {
              this.isEduEndDate = _result.data.evaluationDate;
              this.isEduEnd = true;
            }
          } else {
            this.isPost = true;
            this.isFirstPlay = false;
          }
        },);
      }
    },
    savePlayTime() {
      if (this.isPost) {
        this.$http.url = this.vodInsertUrl;
        this.$http.type = 'POST';
        this.isPost = false;
      } else {
        this.$http.url = this.vodUpdateUrl
        this.$http.type = 'PUT';
      }
      this.$http.isLoading = false;
      this.$http.param = {
        eduEducationId: this.popupParam.eduEducationId,
        attendeesId: this.$store.getters.user.userId,
        vodTotalTime: this.vodTotalDurationS,
        // vodTotalTime: this.player.cache_.duration,
        vodPlayTime: this.playDuraionTime,
        vodCurrentTime: this.player.currentTime(),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      }
      this.$http.request(() => {
      },);
    },
    // 실제 플레잉되고 있는 경우 초 증가처리 (Video-player 컴포넌트 playing 이벤트)
    onPlayerStart() {
      console.log('a')
      this.isInterval = setInterval(() => {
        this.playDuraionTime++;
        this.vodPlayDuration = this.getPlayTime(this.playDuraionTime);
      }, 1000);
    },
    onPlayerPlayFirst() {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = '재생';
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';
      this.isFirstPlay = false;
      this.playDuraionTime = 0;
      this.player.currentTime(0)
      this.onPlayerPlay();
    },
    onPlayerPlay () {
      if (!this.isPlay) {
        // this.vodTotalDurationS = this.player.cache_.duration;
        // this.vodTotalDuration = this.getPlayTime(this.vodTotalDurationS);
        // console.log(this.vodTotalDuration)
        this.player.play();
        this.isPlay = true;
        this.playLabel = '중지';
        this.playColor = 'red';
        this.playIcon = 'pause';
      } else {
        clearInterval(this.isInterval);
        this.player.pause();
        this.isPlay = false;
        this.playLabel = '재생';
        this.playColor = 'blue';
        this.playIcon = 'play_arrow';

        if ((this.playDuraionTime / this.vodTotalDurationS) * 100 > 90) {
          this.isEnd = true;
        }
        // 중지 시 재생시간 저장
        this.savePlayTime();
      }
    },
    onPlay() {
      this.vodTotalDurationS = this.player.cache_.duration;
      this.vodTotalDuration = this.getPlayTime(this.player.cache_.duration);
      this.player.play();
      this.isPlay = true;
      this.playLabel = '중지'; // 중지
      this.playColor = 'red';
      this.playIcon = 'pause';
    },
    onPause() {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = '재생'; // 재생
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';

      if ((this.playDuraionTime / this.vodTotalDurationS) * 100 > 90) {
        this.isEnd = true;
      }
      // 중지 시 재생시간 저장
      this.savePlayTime();
    },
    onPlayerEnded () {
      clearInterval(this.isInterval);
      this.player.pause();
      this.isPlay = false;
      this.playLabel = '재생';
      this.playColor = 'blue';
      this.playIcon = 'play_arrow';
      this.savePlayTime();
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source
      }
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video)
    },
    getPlayTime(_min) {
      let m = parseInt(_min / 60);
      let s = parseInt(_min % 60);
      return m + '분 ' + s + '초'
    },
    setSize() {
      this.$refs.signaturePad.fromDataURL(this.educationInfo.electronSignature);
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getDetail();
    },
    callback1(data, rowIndex) {
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
    saveAsSvg() {
      let data = ''
      if(this.$refs.signaturePad.saveSignature()) data = this.$refs.signaturePad.saveSignature().data
      this.educationInfo.electronSignature = data;
      if (!this.educationInfo.electronSignature) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '서명하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '교육 이수를 완료하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.updateSignatureUrl;
            this.$http.type = 'PUT';
            this.$http.param = {
              electronSignature: this.educationInfo.electronSignature,
              attendeesId: this.educationInfo.attendeesId,
              eduEducationId: this.educationInfo.eduEducationId,
              opinionContent: this.educationInfo.opinionContent,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              checkCompleteFlag: 'Y'
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              // this.getDetail();
              this.educationInfo.checkCompleteFlag = 'Y';
              // this.$emit('closePopup');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    eraseSignature() {
      this.$refs.signaturePad.clearSignature();
      this.educationInfo.electronSignature = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    openQuiz() {
      if (this.educationInfo.eduQuestionFlag == 'Y') {
        this.popupOptions.title = '교육 문제풀이';
        this.popupOptions.width = '99%';
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          eduEducationId: this.educationInfo.eduEducationId,
          eduQuestionMstId: this.educationInfo.eduQuestionMstId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/edu/quiz/solvingQuiz.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeQuizPopup;
      }
    },
    closeQuizPopup(_type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
      if (_type == 'SUCCESS') {
        this.isEduQuestionPass = true;
      } else {
        this.isEduQuestionPass = false;
      }
    },
  }
};
</script>
<style>
#signature {
  border: double 1px transparent;
  border-radius: 2;
  background-image: linear-gradient(white, white),
      /* radial-gradient(circle at top left, #4bc5e8, #9f6274); */
  radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: "100%";
  /* margin-top: 5px !important; */
  margin-bottom: 20px !important;
  padding: 0px 8px 0px 2px
}
#signatureTitle {
  color: #3199c5 !important;
  font-size: 0.9em !important;
  font-weight: 600;
}
.mobilevod-hide {
  display: none;
}
.mobilevod-show {
  display: block;
}
  .video-js {
    margin: 0 auto !important;
    /* display: contents !important; */
  }
  .player {
    position: absolute !important;
    width: 100%;
    height: 60%;
  }
  .vjs-custom-skin {
    height: 60% !important;
  }

  .vjs-custom-skin /deep/ .video-js {
    height: 60%;
  }

  .vjs-big-play-centerd .vjs-big-play-button {
    display: none !important;
  }

  .signature-border {
    border: 1px solid #888888
  }

  .vod-label-div {
    padding-bottom: 10px;
    font-weight: 600;
  }
  .vod-label-div2 {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .vod-label-layer {
    border: 1px solid #d8d7d7;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px !important;
  }
  .vod-label-layer-vod {
    border: 1px solid #d8d7d7;
    background-color: #fdfdfd;
    border-radius: 10px;
    padding: 10px !important;
  }
  .vod-top-layer {
    background-color: #2196f3;
    padding: 10px !important;
    font-size: 1.4em;
    font-weight: 700;
    color: #fff;
  }
  .vod-label-div-text {
    padding-top: 20px;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 30px;
  }
</style>